const userRoutes = {
	path: '/user',
	name: 'user',
	redirect: { name: 'user.account' },
	meta: { requiresAuth: true, },
	children: [
		{
			path: 'account',
			name: 'user.account',
			meta: { requiresAuth: true },
			component: () => import('@/modules/user/pages/AccountPage.vue')
		},
		{
			path: 'preferences',
			name: 'user.preferences',
			meta: { requiresAuth: true },
			component: () => import('@/modules/user/pages/UserPreferences.vue')
		}
	]
}
export default userRoutes
